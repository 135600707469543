import React, { useEffect, useState } from 'react';
import { navigate } from "gatsby";
import CerebroLogo from '@icons/ic-cerebro.svg';
import { getToken } from "@repository/client";
import './MeetYourMentor.scss';
import ImgGugun from '@mentors/gugun.jpg';
import ImgOsvaldo from '@mentors/osvaldo.jpg';
import ImgDara from '@mentors/dara.jpg';
import ImgSaid from '@mentors/said.jpg';
import ImgGideon from '@mentors/gideon.jpg';
import ImgSari from '@mentors/sari.jpg';
import ImgMinarti from '@mentors/minarti.jpg';

function MeetYourMentor() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  
  const mentors = [
    {
      name: 'Gugun Sahat Rouli Purba',
      description: 'Orthopedic',
      instagram: 'https://instagram.com/rouli.gugun?utm_medium=copy_link',
      linkedin: 'https://www.linkedin.com/in/gugun-purba-440035146',
      profile_picture: ImgGugun,
      teaching_times: [
        "Wednesday, 25 August 2021 | 15.00-17.00",
        "Thursday, 26 August 2021 | 14.00-16.00",
        "Friday, 27 August 2021 | 20.00-22.00"
      ],
      preclinical_gpa: "3.53",
      clinical_gpa: "3.65",
      ukmppd: "85.5",
    },
    {
      name: 'Osvaldo Williamson',
      description: 'Cardiology',
      instagram: 'https://www.instagram.com/osvaldowilliamson/',
      linkedin: 'https://www.linkedin.com/in/osvaldo-williamson-13a42721a',
      profile_picture: ImgOsvaldo,
      teaching_times: [
        "Tuesday, 24 August 2021 | 15.00-17.00",
        "Friday, 27 August 2021 | 19.00-21.00",
        "Saturday, 28 August 2021 | 10.00-12.00"
      ],
      preclinical_gpa: "3.66",
      clinical_gpa: "3.65",
      ukmppd: "81"
    },
    {
      name: 'Dara Hanifa Rahman',
      description: 'Obstetric & Gynecology',
      instagram: 'https://www.instagram.com/radarahanifa/',
      linkedin: 'https://www.linkedin.com/in/dara-hanifa-rahman-128b89212',
      profile_picture: ImgDara,
      teaching_times: [
        "Thursday, 26 August 2021 | 19.00-21.00",
        "Friday, 27 August 2021 | 19.00-21.00",
        "Saturday, 28 August 2021 | 10.00-12.00"
      ],
      preclinical_gpa: "3.42",
      clinical_gpa: "3.81",
      ukmppd: "84"
    },
    {
      name: 'Said Fachlefi',
      description: 'Dermatovenereology',
      instagram: 'https://www.instagram.com/said.fachlefi/',
      linkedin: 'https://www.linkedin.com/mwlite/in/said-fachlefi-569084183',
      profile_picture: ImgSaid,
      teaching_times: [
        "Wednesday, 25 August 2021 | 20.30-22-30",
        "Friday, 27 August 2021 | 20.30-22.30",
        "Sunday, 29 August 2021 | 20.30-22.30"
      ],
      preclinical_gpa: "3.74",
      clinical_gpa: "3.72",
      ukmppd: "87.5",
    },
    {
      name: 'Gideon M. Silalahi',
      description: 'Respiratory',
      instagram: 'https://www.instagram.com/gideonm.silalahi/',
      linkedin: 'https://www.linkedin.com/in/gideon-m-silalahi-1b648221a',
      profile_picture: ImgGideon,
      teaching_times: [
        "Tuesday, 24 August 2021 | 15.00-17.00",
        "Wednesday, 25 August 2021 | 15.00-17.00",
        "Friday, 27 August 2021 | 15.00-17.00"
      ],
      preclinical_gpa: "3.65",
      clinical_gpa: "3.47",
      ukmppd: "81.5",
    },
    {
      name: 'Sari Margaretha Situmorang',
      description: 'Internal Medicine (Hematology & Immunology)',
      instagram: 'https://www.instagram.com/sarimargaretha_s/',
      linkedin: 'http://linkedin.com/in/sari-margaretha-situmorang-5b248721a',
      profile_picture: ImgSari,
      teaching_times: [
        "Tuesday, 24 August 2021 | 13.00-15.00",
        "Wednesday, 25 August 2021 | 13.00-15.00",
        "Thursday, 26 August 2021 | 13.00-15.00"
      ],
      preclinical_gpa: "3.44",
      clinical_gpa: "3.78",
      ukmppd: "83",
    },
    {
      name: 'Minarti Helenta Harianja',
      description: 'Internal Medicine (Gastroenterohepatology)',
      instagram: 'https://www.instagram.com/minartihrj/',
      linkedin: 'https://www.linkedin.com/in/minarti-harianja-577b341ab',
      profile_picture: ImgMinarti,
      teaching_times: [
        "Thursday, 26 August 2021 | 16.00-18.00",
        "Friday, 27 August 2021 | 16.00-18.00",
        "Saturday, 28 August 2021 | 16.00-18.00"
      ],
      preclinical_gpa: "3.54",
      clinical_gpa: "3.69",
      ukmppd: "83.5",
    },
  ];

  return (
    <div id="meet-your-mentor">
      <div id="introduction-section" className="d-flex">
        <div className="w-100 h-100 position-absolute background" />
        <div className="container d-flex flex-column align-items-center justify-content-center m-auto">
          <img src={CerebroLogo} alt="cerebro logo" />
          <div className="dosis-large-title text-pure-white text-center mb-3">
            Meet Your Mentor
          </div>
          <div className="d-flex flex-sm-row flex-column">
          <a
              type="button"
              className="button-lg button-default text-philadelphia-blue mr-sm-3 text-center mb-sm-0 mb-3"
              href='https://docs.google.com/forms/d/e/1FAIpQLSfd7wS9k35KoYgnUrasgRjg6xami1wCnOuw1qXbdBhO1VPbdg/viewform'
              target="_blank">
              <span className="font-weight-normal">Mentoring Now</span></a>
            <a
              type="button"
              className="button-lg button-default-ghost text-white mr-sm-3"
              href='http://bit.ly/cerebroPALmentors'
              target="_blank">
              <span className="font-weight-normal">Let’s be a Mentor</span>
            </a>
          </div>
        </div>
      </div>
      <div id="mentors-section" className="container py-5">
        <div className="row">
          {mentors.map((mentor, index) => (
            <div className="col-lg-3 col-md-4 col-sm-6 mb-sm-5 mb-4" key={`mentor-${index}`}>
              <div
                className="img-mentor w-100 mb-2"
                  style={{
                      backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.1) 50.1%, rgba(0, 0, 0, 1) 100%), url(${mentor.profile_picture})`
                  }}>
                  <div className="d-flex align-items-center social-medias">
                    <a
                      href={`${mentor.instagram}`}
                      target="_blank"
                      className="d-flex align-items-center justify-content-center bg-philadelphia-blue icon-wrapper mr-3">
                      <div className="mdi mdi-instagram mdi-24px text-pure-white"></div>
                    </a>
                    <a
                      href={`${mentor.linkedin}`}
                      target="_blank"
                      className="d-flex align-items-center justify-content-center bg-philadelphia-blue icon-wrapper">
                      <div className="mdi mdi-linkedin mdi-24px text-pure-white"></div>
                    </a>
                  </div>
                  <div className="mentor-information d-flex flex-column">
                    <div className="dosis-title-3 font-weight-bold mb-1 mentor-name text-pure-white">{mentor.name}</div>
                    <div className="asap-body text-pure-white mb-1 mentor-description">{mentor.description}</div>
                    <ul className="mb-0 pl-3">
                      <li className="asap-caption-1 text-pure-white">
                        <b>{mentor.preclinical_gpa}</b> Preclinical GPA
                      </li>
                      <li className="asap-caption-1 text-pure-white">
                        <b>{mentor.clinical_gpa}</b> Clinical GPA
                      </li>
                      <li className="asap-caption-1 text-pure-white">
                        <b>{mentor.ukmppd}</b> UKMPPD Score
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="dosis-title-3 font-weight-bold text-philadelphia-blue mb-1">Teaching Time</div>
                <ul className="mb-0 pl-3">
                  {mentor.teaching_times.map((teachingTime, index) => (
                    <li className="asap-caption-1 mb-1" key={`teachingtime-${index}`}>
                      {teachingTime}
                    </li>
                  ))}
                </ul>
            </div>
          ))}
        </div>
      </div>

    </div>
  );
}

export default MeetYourMentor;
